import { Box, Button, Text, Flex, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import GVideoLoud from "./GVideoLoud"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink"
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion"
import React from "react"
import { chakra } from "@chakra-ui/react"
import { MdEmail, MdPhone, MdClose } from "react-icons/md"

const ChakraBox1 = chakra(motion.div, {
  shouldForwardProp: isValidMotionProp,
})
export const ModalAd = ({ isToggled, setToggle, children }) => {
  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          position="relative"
          top={{ base: "0px", lg: "10px" }}
          left={{ base: 0, lg: "12%" }}
          initial={{ opacity: 0.4 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.5 }}
          mt={{ base: 0, lg: "5vh" }}
        >
          <Box
            w={{ base: "92vw", md: "70vw" }}
            mt={{ base: "2vh", lg: "5vh" }}
            h={{ base: "98vh", md: "88vh" }}
            as={motion.div}
            position="fixed"
            zIndex="99999"
            overflow={{ base: "scroll", md: "hidden" }}
            initial={{ y: 50, scale: 0.2 }}
            animate={{ y: 0, scale: 1 }}
            exit={{ y: -50, scale: 0.1 }}
            p={{ base: 2, lg: 8 }}
            top={"0"}
            left={{ base: "4%", md: "12%" }}
            boxShadow="2xl"
            bg={"#ffffff"}
            borderRadius="11px"
          >
            <Button
              position={{ base: "fixed", md: "absolute" }}
              right={12}
              top={{ base: 6, md: 4 }}
              w={100}
              mt={{ base: 0, md: 12 }}
              textAlign="center"
              variant={"round"}
              color="brand.violet.light"
              onClick={() => setToggle(false)}
            >
              <Text> schliessen</Text>
              <MdClose minW={12} size={24} color="brand.violet.medviolet" />
            </Button>
            <Flex
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", md: "space-between" }}
              py={{ base: 12, md: 24 }}
            >
              <GVideoLoud
                borderRadius={24}
                overflow="hidden"
                w={{ base: "87%", md: "45%" }}
                src={"ad-docs.mp4"}
              />
              <Box w={{ base: "100%", md: "45%" }}>
                <Heading
                  fontSize={{ base: "xl", lg: "2xl" }}
                  fontWeight="normal"
                  color="brand.violet.light"
                  mb="4"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Wir suchen: MKG-Chirurg/in, Oralchirurg/in Wilhelmshaven
                </Heading>
                <Text
                  color="brand.gray.light"
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="normal"
                >
                  Moderne Mehrbehandlerpraxis mit breitem Behandlungsspektrum
                  inkl. rekonstruktive & ästhetische Gesichtschirurgie mit
                  Weiterbildungsberechtigung für Oralchirurgie und
                  (MKG-Chirurgie in Beantragung) sucht eine/n MKG-Chirurg/in
                  oder eine/n Oralchirurg/in in Vollzeit.
                </Text>
                <Text
                  my={8}
                  color="brand.gray.med"
                  fontSize={{ base: "sm", md: "md" }}
                  fontWeight="bold"
                >
                  Wir freuen uns auf Ihre Bewerbung!
                </Text>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} href="/kontakt">
                    <Button mt={4} ml={{ base: 0, md: 0 }} variant="solid">
                      Zum Kontaktformular
                    </Button>
                  </RouterLink>
                  <Link href="tel.: 04421-42033">
                    <Button
                      mt={4}
                      size="md"
                      height={{ base: "56px", lg: "72px" }}
                      variant="ghost"
                      color="brand.violet.primary"
                      _hover={{ border: "2px solid #aaa" }}
                      leftIcon={<MdPhone color="#232835" />}
                    >
                      Tel. 04421-42033
                    </Button>
                  </Link>
                </FadeInAnimation>
              </Box>
            </Flex>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
