import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Box } from "@chakra-ui/react"
import { motion } from "framer-motion"

function renderVideo(file, restProps, alt) {
  return (
    <Box {...restProps}>
      <motion.video controls autoPlay preload="auto" loop {...restProps}>
        <source src={file.node.videoH264.path} type="video/mp4; codecs=avc1" />
        <source src={file.node.videoVP9.path} type="video/webm; codecs=vp9" />
      </motion.video>
    </Box>
  )
}

const GVideoLoud = function ({ src, videoScreenshots, alt, ...restProps }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          videos: allFile(filter: { extension: { eq: "mp4" } }) {
            edges {
              node {
                relativePath
                videoH264 {
                  path
                }
                videoVP9 {
                  path
                }
              }
            }
          }
        }
      `}
      render={({ videos }) =>
        renderVideo(
          videos.edges.find(video => video.node.relativePath === src),
          restProps,
          alt
        )
      }
    />
  )
}
export default GVideoLoud
